import { useState, useEffect } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import moment from "moment";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import SideLogo from "../../assets/images/logo-white.png";
import { useToken } from "../../context/TokenContext";
import dotnetApiClient from "../../config/dotnetApiClient";
import { formatDateTime, logErrorFunction } from "../../helper/service";

interface ClientType {
  id: number;
  name: string;
  email: string;
  phone: string;
  progressPercentage: number;
  caseType: "Marriage AOS";
  createDtUtc: string;
  lastLoginDtUtc: string;
  formsForCaseTypes: FormsForCaseTypes[];
}

interface FormsForCaseTypes {
  caseID: number;
  caseName: string;
  formNames: string[];
}

interface IUserDocument {
  udId: string;
  userId: string;
  originalFileName: string;
  title: string;
  description: string;
  selectedClientDetails: any;
}

const ViewComponent = (props: any) => {
  const { isViewPopup, setIsView, userId, selectedClientDetails } = props;
  const [loading, setLoading] = useState(false);
  const [clientData, setClientData] = useState<ClientType>();
  const [uploadedDocuments, setUploadedDocuments] = useState<IUserDocument[]>(
    []
  );
  const [packetHistory, setPacketHistory] = useState<any>([]);
  const { token } = useToken();
  const navigate = useNavigate();
  const [selectedCaseType, setSelectedCaseType] = useState(0);
  const [selectedCaseTypeFormList, setSelectedCaseTypeFormList] = useState([]);

  const getUserPDFDownload = async (_request: any) => {
    const { userId, formName, caseId } = _request;
    setLoading(true);

    try {
      const response = await dotnetApiClient().get(
        `/pdfpreview/user/${userId}/form/${formName}?caseId=${caseId}`,
        {
          responseType: "arraybuffer",
        }
      );

      if (response.data) {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `Form_${formName}_${new Date()
          .toISOString()
          .replace(/[:.]/g, "_")}.pdf`;
        link.click();
      }
    } catch (error: any) {
      logErrorFunction("Error downloading PDF:" + error);

      if (
        error?.response?.status === 401 ||
        error?.response?.status === 406 ||
        error?.response?.status === 406
      ) {
        localStorage.removeItem("billingEntityId");
        navigate("/login");
      }
    } finally {
      setLoading(false);
    }
  };
  const handleDownloadPacketFromHistory = async (_request: any) => {
    const { versionId } = _request;
    setLoading(true);

    try {
      const response = await dotnetApiClient().get(
        `/evidencePacket/versions/${versionId}/download`,
        {
          responseType: "arraybuffer",
        }
      );

      if (response.data) {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `Packet_${versionId}_${new Date()
          .toISOString()
          .replace(/[:.]/g, "_")}.pdf`;
        link.click();
      }
    } catch (error: any) {
      logErrorFunction("Error downloading PDF:" + error);
    } finally {
      setLoading(false);
    }
  };

  const handlePdfClick = (pdfName: any) => {
    const userFormData = {
      userId,
      formName: pdfName,
      caseId: selectedCaseType,
    };
    getUserPDFDownload(userFormData);
  };

  const getEvidencePacketDownload = async (_request: any) => {
    const { userId, caseId } = _request;
    setLoading(true);

    try {
      const response = await dotnetApiClient().get(
        `/evidencepacket/download/user/${userId}?caseId=${caseId}`,
        {
          responseType: "arraybuffer",
        }
      );

      if (response.data) {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `Evidence_Form_${new Date()
          .toISOString()
          .replace(/[:.]/g, "_")}.pdf`;
        link.click();
      }
    } catch (error: any) {
      logErrorFunction("Error downloading evidence packet:" + error);

      if (error?.response?.status === 401 || error?.response?.status === 406) {
        localStorage.removeItem("billingEntityId");
        navigate("/login");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleDownloadPacket = () => {
    const userFormData = {
      userId,
      caseId: selectedCaseType,
    };
    getEvidencePacketDownload(userFormData);
  };

  const fetchDocuments = async () => {
    setLoading(true);
    try {
      const response = await dotnetApiClient().get(
        `/UserDocument/user/${userId}/list`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setUploadedDocuments(response.data);
    } catch (error: any) {
      logErrorFunction("Error fetching documents:" + error);
      if (error?.response?.status === 401 || error?.response?.status === 406) {
        localStorage.removeItem("billingEntityId");
        navigate("/login");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleDownloadDocument = (doc: IUserDocument) => {
    const linkUrl = `${dotnetApiClient().defaults.baseURL}/UserDocument/user/${
      doc.userId
    }/preview/${doc.udId}`;

    fetch(linkUrl, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "*/*",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.blob();
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", doc.originalFileName);
        document.body.appendChild(link);
        link.click();
        link.parentNode?.removeChild(link);
      })
      .catch((error) => {
        logErrorFunction(
          "There was a problem with the download request:" + error
        );
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await dotnetApiClient().get(`/clients/${userId}`);
        const data = response.data;

        if (data) {
          const tempSelectedData = {
            id: data.id,
            name: data.name,
            email: data.email,
            phone: data.phoneNumber,
            progressPercentage: data.progressPercentage,
            caseType: data.caseType,
            createDtUtc: data.createDtUtc,
            lastLoginDtUtc: data.lastLoginDtUtc,
            formsForCaseTypes: data?.formsForCaseTypes,
          };

          setSelectedCaseType(
            selectedClientDetails?.formsForCaseTypes[0]?.caseId
          );
          setSelectedCaseTypeFormList(data?.formsForCaseTypes[0]?.formNames);
          setClientData(tempSelectedData);
        }
      } catch (error: any) {
        logErrorFunction("Error fetching data:" + error);

        if (
          error?.response?.status === 401 ||
          error?.response?.status === 406
        ) {
          localStorage.removeItem("billingEntityId");
          navigate("/login");
        }
      } finally {
        setLoading(false);
      }
    };

    fetchData();
    fetchDocuments();
  }, [userId, token]);

  const formLabels: { [key: string]: string } = {
    "i-864a-household-member-1": "I864a Household Member of Petitioner",
    "i-864a-household-member-2": "I864a Household Member of Joint Sponsor",
    "i-864-joint-sponsor": "I864 for Joint Sponsor",
    "g-28-immigrant": "G28 for Immigrant",
    "g-28-petitioner": "G28 for Petitioner",
  };

  const handleCaseType = (e: any) => {
    setSelectedCaseType(e?.target?.value);
  };
  useEffect(() => {
    const getPacketHistory = async () => {
      try {
        const axiosInstance = dotnetApiClient();
        const response = await axiosInstance.get(
          `/evidencePacket/user/${userId}/case/${selectedCaseType}/versions`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.data) {
          setPacketHistory(response?.data);
        } else {
          logErrorFunction("Failed to delete client");
        }
      } catch (error: any) {
        logErrorFunction("Error deleting client:" + error);
      }
    };
    getPacketHistory();
  }, [selectedCaseType, userId]);

  useEffect(() => {
    const updatedPDFList: any = clientData?.formsForCaseTypes?.filter(
      (x: any) => x?.caseId === selectedCaseType
    );
    setSelectedCaseTypeFormList(updatedPDFList?.[0]?.formNames);
  }, [selectedCaseType]);

  const renderMenuItem = () => {
    return clientData?.formsForCaseTypes?.map((list: any, index: number) => (
      <MenuItem key={index} value={list.caseId}>
        {`${list.caseName}`}
      </MenuItem>
    ));
  };

  return (
    <Dialog
      open={isViewPopup}
      onClose={() => setIsView(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="dialog-view-client"
    >
      {loading && (
        <div className="loader_dialog">
          <img src={SideLogo} className="mb-3" alt="sidelogo" />
          <CircularProgress sx={{ color: "#fff" }} />
          <span className="mt-3 text-white">Getting things ready...</span>
        </div>
      )}
      <>
        <Box
          display="flex"
          justifyContent={"space-between"}
          sx={{ borderBottom: 1, borderColor: "#eee" }}
        >
          <DialogTitle>Client Details</DialogTitle>
          <Button variant="text" onClick={() => setIsView(false)}>
            <CloseIcon style={{ color: "red" }} />
          </Button>
        </Box>

        <DialogContent sx={{ mt: "20px" }}>
          <div className="userViewDetail">
            <div className="basicDetails" style={{ marginBottom: "20px" }}>
              <div style={{ marginBottom: "10px" }}>
                <strong>Name:</strong> {clientData?.name || ""}
              </div>
              <div style={{ marginBottom: "10px" }}>
                <strong>Email:</strong> {clientData?.email || ""}
              </div>
              <div style={{ marginBottom: "10px" }}>
                <strong>Case Type:</strong>{" "}
                {clientData?.formsForCaseTypes[0]?.caseName || ""}
              </div>
              <div style={{ marginBottom: "10px" }}>
                <strong>Progress %:</strong>{" "}
                {clientData?.progressPercentage !== undefined
                  ? `${clientData?.progressPercentage}`
                  : "N/A"}
              </div>
              <div style={{ marginBottom: "10px" }}>
                <strong>Phone:</strong> {clientData?.phone || ""}
              </div>
              <div style={{ marginBottom: "10px" }}>
                <strong>Last login at:</strong>{" "}
                {clientData?.lastLoginDtUtc
                  ? formatDateTime(clientData?.lastLoginDtUtc)
                  : "N/A"}
              </div>
            </div>

            <div style={{ marginBottom: "20px" }}>
              <strong>Forms: </strong>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: 0,
                }}
              >
                <label style={{ fontWeight: "700" }}>Select Case Type: </label>
                <FormControl sx={{ m: 1, minWidth: 100 }}>
                  <InputLabel id="demo-simple-select-label">
                    Case Type
                  </InputLabel>
                  <Select
                    labelId="caseTypeSelection"
                    id="caseTypeSelection"
                    value={selectedCaseType}
                    label="Case Type"
                    name="CaseType"
                    onChange={(e: any) => handleCaseType(e)}
                  >
                    {renderMenuItem()}
                  </Select>
                </FormControl>
              </div>
              <div>
                {selectedCaseTypeFormList?.map((urls: string) => (
                  <Button
                    key={urls}
                    onClick={() => handlePdfClick(urls)}
                    sx={{
                      px: "10px",
                      py: "4px",
                      background: "#2557b3",
                      borderRadius: "8px",
                      mx: "6px",
                      my: "2px",
                      minWidth: "80px",
                      fontSize: "12px",
                      color: "#fff",
                      ":hover": {
                        backgroundColor: "rgba(37, 87, 179, 0.9)",
                      },
                    }}
                  >
                    {formLabels[urls] || urls}
                  </Button>
                ))}
                {/* {pdfUrlList?.map((urls: string) => (
                  <Button
                    key={urls}
                    onClick={() => handlePdfClick(urls)}
                    sx={{
                      px: "10px",
                      py: "4px",
                      background: "#2557b3",
                      borderRadius: "8px",
                      mx: "6px",
                      my: "2px",
                      minWidth: "80px",
                      fontSize: "12px",
                      color: "#fff",
                      ":hover": {
                        backgroundColor: "rgba(37, 87, 179, 0.9)",
                      },
                    }}
                  >
                    {formLabels[urls] || urls}
                  </Button>
                ))} */}
              </div>
            </div>

            <div style={{ marginBottom: "10px" }}>
              <strong>Evidence packet: </strong>
              <div>
                <Button
                  onClick={() => handleDownloadPacket()}
                  sx={{
                    px: "10px",
                    py: "4px",
                    background: "#2557b3",
                    borderRadius: "8px",
                    mx: "6px",
                    my: "2px",
                    minWidth: "80px",
                    fontSize: "12px",
                    color: "#fff",
                    ":hover": {
                      backgroundColor: "rgba(37, 87, 179, 0.9)",
                    },
                  }}
                >
                  Download Packet
                </Button>
              </div>
            </div>
            <div style={{ marginBottom: "10px" }}>
              <strong>Packet history: </strong>
              {packetHistory?.length ? (
                packetHistory?.map((p: any) => (
                  <div className="mb-2">
                    <Button
                      onClick={() =>
                        handleDownloadPacketFromHistory({ versionId: p?.id })
                      }
                      sx={{
                        mr: "10px",
                        minWidth: "80px",
                        fontSize: "12px",
                        fontWeight: 600,
                        color: "#2557b3",
                      }}
                    >
                      Download
                    </Button>
                    {formatDateTime(p?.uploadedOnDtUtc)}
                  </div>
                ))
              ) : (
                <p>No packet version history found</p>
              )}
            </div>

            <div style={{ marginBottom: "10px", marginTop: "30px" }}>
              <strong>Uploaded Documents:</strong>
              <TableContainer
                component={Paper}
                sx={{ mt: 2, maxHeight: "400px", overflow: "auto" }}
              >
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ fontSize: "14px", fontWeight: "bold" }}>
                        Title
                      </TableCell>
                      <TableCell sx={{ fontSize: "14px", fontWeight: "bold" }}>
                        Description
                      </TableCell>
                      <TableCell sx={{ fontSize: "14px", fontWeight: "bold" }}>
                        Document
                      </TableCell>
                      <TableCell sx={{ fontSize: "14px", fontWeight: "bold" }}>
                        Action
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {uploadedDocuments.length > 0 ? (
                      uploadedDocuments.map((doc) => (
                        <TableRow key={doc.udId}>
                          <TableCell sx={{ fontSize: "12px" }}>
                            {doc.title}
                          </TableCell>
                          <TableCell sx={{ fontSize: "12px" }}>
                            {doc.description}
                          </TableCell>
                          <TableCell sx={{ fontSize: "12px" }}>
                            {doc.originalFileName}
                          </TableCell>
                          <TableCell>
                            <Button
                              onClick={() => handleDownloadDocument(doc)}
                              sx={{
                                px: "8px",
                                py: "2px",
                                background: "#2557b3",
                                color: "#fff",
                                fontSize: "12px",
                                ":hover": {
                                  backgroundColor: "rgba(37, 87, 179, 0.9)",
                                },
                              }}
                            >
                              Download
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={4} sx={{ textAlign: "center" }}>
                          No documents uploaded
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        </DialogContent>
      </>
    </Dialog>
  );
};

export default ViewComponent;
